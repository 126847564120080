// colors
$dark : #1a1a1a;
$dark-light : #4d4d4d;
$dark-middle: #333;
$green : #3b8d16;
$orange : #f95b00;
$grey-light : #f6f6f6;
$grey : #e6e6e6;
$grey-middle : #999;
$grey-light: #f8f8f8;
$red : #ee3023;

label, a, input, button, div {
	outline: none;
	&:focus, &:active {
		outline: none; } }
input {
	@include box; }
img {
	display: block; }

input, button {
	border: none; }

a {
	text-decoration: none;
	color: $dark; }
ul {
	list-style-type: none; }

.hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	@include size(1px);
	margin: -1px;
	padding: 0;
	border: 0; }


.container-full {
	max-width: 100%;
	width: 100%; }

.function-error {
	display: inline-block; }

.col {
	@include flex;
	&-start {
		align-items: flex-start; }
	&-half {
		max-width: 50%;
		width: 100%; } }

.flex {
	@include flex;
	align-items: flex-start;
	&-center {
		align-items: center; } }

.btn-default {
	font-size: 18px;
	line-height: 18px;
	padding: 19px 38px;
	@include border-radius(30px);
	color: #fff;
	@include deft;
	display: inline-block; }

.btn-green {
	background-color: $green;
	&:hover {
		box-shadow: 0 0 16px 4px rgba(77, 176, 32, 0.4); } }
.btn-white {
	background-color: #fff;
	color: $dark;
	&:hover {
		box-shadow: 0 0 16.2px 1.8px rgba(255, 255, 255, 0.4); } }
.btn-orange {
	background-color: $orange;
	&:hover {
		box-shadow: 0 0 16px 4px rgba(255, 110, 26, 0.4); } }
.btn-more-posts {
	@include cen;
	display: block; }
