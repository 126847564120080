@import "normalize";
@import "bourbon";
@import "_mixins";
@import "_defaults";
@import "_preloader";
@import "libs/bootstrap/bootstrap-grid.scss";


body {
	overflow-x: hidden; }

.preloader {
	height: 100vh;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	@include flex;
	z-index: 999;
	background-color: #fff;
	.sk-wave .sk-rect {
		background-color: $green; } }



.header-main {
	// display: none
	&_toggler, &_languages-mobile {
		display: none; }
	&_top, &_bottom {
		> .container {
			max-width: 1650px;
			padding: 0 15px; } }
	&.header-main_fixed {
		.header-main_top {
			box-shadow: 0 0 15.2px 0.8px rgba(0, 0, 0, 0.2);
			display: none; }
		.header-main_bottom {
			visibility: hidden;
			@include opacity(0);
			display: none; } }
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		@include flex;
		align-items: flex-start; } }

.header-main {
	@include deft;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
	background-color: #fff;
	&_top {
		padding: 10px 0; }
	&_phones {
		.col-half {
			.main_phone:first-of-type {
				margin-bottom: 13px; } } }
	//
	&_logo {
		max-width: 284px;
		width: 100%;
		margin-right: calc(55/1650 * 100%); }
	//
	&_right {
		> .row {
			width: 100%; }
		&-top {
			@include flex;
			justify-content: flex-end;
			margin-bottom: 15px;
			.menu {
				padding-left: 107px;
				position: relative;
				&:after {
					content: '';
					@include size(100%, 1px);
					position: absolute;
					bottom: 0;
					left: 0;
					background-image: linear-gradient(to left, #e6e6e6 0%, #ededed 29%, white 100%); }
				li:not(:last-of-type) {
					margin-right: 40px; }
				li {
					padding-bottom: 5px;
					&:hover a {
						color: $green; }
					&:hover:after {
						display: none; } }
				a {
					color: $dark-light;
					@include deft; } } }
		&-bottom {
			.col {
				padding: 0; } } }
	//
	&_languages {
		max-width: 110px;
		width: 100%;
		list-style-type: none;
		@include flex;
		justify-content: flex-start;
		&-mobile {
			display: none; }
		li {
			padding: 5px;
			&:not(:last-of-type) {
				margin-right: 4px; }
			&.lang-item-uk a {
				background-image: url('../images/flag-ukr.svg'); }
			&.lang-item-ru a {
				background-image: url('../images/flag-ru.svg'); }
			a {
				display: block;
				height: 100%;
				@include size(40px, 28px);
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover; }
			img {
				@include size(100%);
				object-fit: cover;
				display: none; }
			&.current-lang {
				background-color: $grey;
				border-radius:  2px; } } }
	//
	&_callback {
		max-width: 240px;
		.btn-orange {
			padding: 11px 32px; } }
	//
	&_bottom {
		padding: 20px 0 0;
		background-color: $green;
		visibility: visible;
		@include opacity(1);
		@include deft;
		.menu {
			justify-content: flex-start;
			> li {
				&:not(:last-of-type) {
					margin-right: 22px; } }
			a {
				font-size: 22px;
				color: #fff;
				text-transform: uppercase; } }
		.menu {
			.home-link {
				a {
					font-size: 0;
					@include size(24px, 20px);
					display: block;
					background-image: url('../images/home_link.svg'); } }
			> li {
				padding-bottom: 12px;
				position: relative;
				&:not(.home-link):after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 9px;
					width: 0;
					height: 1px;
					background-color: #fff;
					@include deft; }
				&:hover:after {
					width: calc(100% - 17px); } }
			> .menu-item-has-children {
				padding-right: 17px;
				position: relative;
				&:hover:before {
					@include rotate(180); }
				&:before {
					content: '';
					position: absolute;
					right: 0;
					top: 8px;
					@include size(10px, 6px);
					background-image: url('../images/link_after.svg');
					background-repeat: no-repeat;
					background-position: center;
					@include deft; }
				.sub-menu {
					visibility: hidden;
					@include opacity(0);
					position: absolute;
					top: 16px;
					left: -21px;
					padding: 20px;
					border-radius: 6px;
					background-color: rgba(26, 26, 26, 0.9);
					margin-top: 20px;
					min-width: 285px;
					@include deft;
					li {
						&:not(:last-of-type) {
							margin-bottom: 20px; }
						&:hover a {
							color: $green; }
						a {
							font-size: 19px;
							line-height: 27px;
							text-transform: uppercase;
							color: #fff;
							display: block;
							@include deft; } } }
				&:hover {
					.sub-menu {
						visibility: visible;
						@include opacity(1); } } } } }
	//
	&_mobile-menu-wrapper {
		height: 0;
		visibility: hidden; } }

.main_phone, .main_email {
	display: block;
	> a {
		@include flex;
		justify-content: flex-start;
		img {
			margin-right: 5px; } }
	&-text {
		font-size: 22px; } }


img.lazy-img {
	@include deft;
	@include opacity(0);
	visibility: hidden;
	&.lazy-loaded {
		@include opacity(1);
		visibility: visible; } }

body.no-overflow {
	overflow-y: hidden; }


@media screen and (min-width: 320px) {
	body.admin-bar {
		.header-main {
			top: 32px; } } }
@media screen and (min-width: 1601px) {
	.header-main_right {
		min-width: 690px; } }
@media screen and (min-width: 1025px) and (max-width: 1600px) {
	.header-main_logo {
		margin-right: 0; }
	.header-main_right {
		min-width: 500px;
		max-width: 500px;
		&-top {
			.menu {
				padding-left: 0; } } }
	.header-main_phones {
		min-width: 510px;
		max-width: 590px;
		padding: 0; }
	.header-main_top-wrapper {
		justify-content: space-between; }
	.header-main_bottom {
		.menu {
			a {
				font-size: 17px; } } } }
@media screen and (max-width: 1600px) {
	.header-main_right-bottom {
		justify-content: flex-end;
		.header-main_email {
			flex: 0 0 100%;
			justify-content: flex-end;
			margin-bottom: 15px; } } }
@media screen and (min-width: 1025px) and (max-width: 1300px) {
	.header-main_phones {
		min-width: 410px;
		max-width: 410px;
		.main_phone-text {
			font-size: 16px; } } }

@media screen and (max-width: 1190px) {
	.header-main {
		&_toggler {
			display: block; }
		&_languages-mobile {
			max-width: 90px;
			@include flex;
			align-self: flex-end;
			li a {
				@include size(32px, 22px); } }
		&_toggler {
			@include size(28px, 23px);
			@include flex;
			margin-top: 16px;
			position: relative;
			&-icon {
				@include size(100%, 3px);
				background-color: $green;
				@include transition(all, 0.55s, ease);
				@include opacity(1); }
			&.toggled {
				z-index: 2;
				.header-main_toggler-icon {
					&:nth-of-type(2) {
						@include opacity(0); }
					&:nth-of-type(1) {
						transform: translateY(9px) rotateZ(-135deg); }
					&:nth-of-type(3) {
						transform: translateY(-7px) rotateZ(135deg); } } } }

		&_logo {
			max-width: 100%;
			flex: 0 0 100%;
			margin-bottom: 14px;
			&-image {
				max-width: 177px;
				width: 100%; } }
		&_top {
			padding-bottom: 10px; }
		&_bottom {
			display: none !important; }
		&_right {
			min-width: 100%;
			max-width: 100%; }
		&_right-top, &_languages.col {
			display: none; }
		&_right-bottom {
			display: none; }
		&_phones.col {
			min-width: 100%;
			max-width: 100%;
			margin-bottom: 10px;
			justify-content: space-around; }
		.col-half {
			width: auto; }
		&_mobile-menu-wrapper {
			@include deft;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			overflow-y: auto;
			background-color: #fff;
			@include opacity(0);
			&.menu-showen {
				display: block;
				@include opacity(1);
				visibility: visible;
				z-index: 1;
				height: 100vh; }
			.inner-content {
				padding: 80px 15px 15px; } } } }
@media screen and (max-width: 767px) {
	.main_phone, .main_email {
			&-text {
				font-size: 14px; }
			> a img {
				width: 18px;
				display: block; } } }
@media screen and(min-width: 420px) and (max-width: 1190px) {
	.header-main_logo-image {
		margin-left: 13%; } }

